import { SliceZone } from "@prismicio/react";
import Layout from "@tpg/components/layouts/default";

import { createClient } from "../../prismicio";
import { components } from "../../slices";

const Page = ({ page, navigation }) => (
	<Layout {...{ navigation }}>
		<SliceZone slices={page.data.slices} components={components} />
	</Layout>
);

export default Page;

export async function getStaticProps({ previewData }) {
	const client = createClient({ previewData });

	const navigation = await client.getSingle("navigation");
	const page = await client.getByUID("page", "home");

	return {
		props: {
			navigation,
			page,
		},
	};
}
