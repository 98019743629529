import Logo from "../Logo";
import styles from "./styles.module.scss";

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className={styles["footer"]}>
			<div className={styles["footer-content"]}>
				<div className={styles["footer-content-group"]}>
					<div>
						<Logo useLight />
					</div>
					<div className={styles["footer-content-links"]}>
						<a href="tel:469-248-7032">469.248.7032</a>
						<a href="mailto:info@theparnellgroup.co">info@theparnellgroup.co</a>
					</div>
				</div>

				<div>
					<p>
						Copyright © {currentYear} The Parnell Group. All Rights Reserved.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
