import Head from "next/head";

import Footer from "@tpg/components/Footer";
import Header from "@tpg/components/Header";
import { Layout } from "./types";

const Layout = ({
	children,
	navigation,
	pageID,
	title = "The Parnell Group",
}: Layout) => (
	<>
		<Head>
			<title>{title}</title>
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
		</Head>

		<div className={`${pageID}-page`} id="tpg">
			<Header {...{ navigation }} />

			<main>{children}</main>
			<Footer />
		</div>
	</>
);

export default Layout;
