import Image from "next/image";
import logo from "/public/img/logo.png";
import logoWhite from "/public/img/logo--white.png";
import Link from "next/link";

interface LogoProps {
	useLight?: boolean;
}
const Logo = ({ useLight = false }: LogoProps) => (
	<>
		{useLight ? (
			<Link href="/" passHref>
				<a>
					<Image
						alt="TPG Logo"
						height={80}
						objectFit={"cover"}
						priority
						src={logoWhite}
						width={110}
					/>
				</a>
			</Link>
		) : (
			<Link href="/" passHref>
				<a>
					<Image alt="TPG Logo" height={133} priority src={logo} width={203} />
				</a>
			</Link>
		)}
	</>
);

export default Logo;
