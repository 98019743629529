import cx from "classnames"
import { useState } from "react"
import { useRouter } from "next/router"
import { PrismicLink } from "@prismicio/react"
import LinkButton from "@tpg/components/LinkButton"
import Logo from "@tpg/components/Logo"
import { Header } from "./types"

import styles from "./styles.module.scss"

const Header = ({ navigation }: Header) => {
	const [activeMenu, setActiveMenu] = useState(false)

	const router = useRouter()

	const onMenuClick = () => {
		setActiveMenu(!activeMenu)
	}

	const { nav } = navigation.data

	return (
		<header className={cx(styles.header)}>
			<div className="container">
				<nav className={styles.nav}>
					<div className={styles.logo}>
						<Logo />
					</div>
					<ul className={styles.desktop}>
						{nav.map((nav, i) => (
							<li key={i}>
								{nav.nav_link.url ===
								"https://app.adaptivetechnologies.io/signin" ? (
									<LinkButton href={nav.nav_link.url} theme="outline">
										{nav.nav_label}
									</LinkButton>
								) : (
									<PrismicLink
										className={cx(
											router.asPath === nav.nav_link.url && styles["activeLink"]
										)}
										field={nav.nav_link}
									>
										{nav.nav_label}
									</PrismicLink>
								)}
							</li>
						))}
					</ul>
					<div
						className={cx(styles["hb-menu"], activeMenu && styles.active)}
						onClick={onMenuClick}
					>
						<span className={styles.top}></span>
						<span className={styles.mid}></span>
						<span className={styles.bottom}></span>
					</div>
					<div className={cx(styles.overlay, activeMenu && styles.open)}>
						<ul className={styles.mobile}>
							{nav.map((nav, i) => (
								<li key={i}>
									<PrismicLink field={nav.nav_link}>
										{nav.nav_label}
									</PrismicLink>
								</li>
							))}
						</ul>
					</div>
				</nav>
			</div>
		</header>
	)
}

export default Header
